<template>
  <div class="regions">
    <div class="regions__select">
      <div class="regions__select-item">
        <p>{{ $t('year') }}</p>
        <ui-select v-model="year" :options="getYears" @input="callApiDistrict"/>
      </div>
    </div>
    <UiLoader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-if="year.value && !loader"
        :title="$t('counties-regions')"
        :descriptionList="[{title: $t('county-region'), width: 420}, {title: $t('short-name'), width: 256, textAlign: 'center', paddingLeft: 0}]"
        :actionText="$t('add-line')"
        :data="getDistricts"
        :sort="true"
        shortName
        hideEditIcon
        @addRowField="addNewDistrict"
        @changeRowField="editAdminDistrict"
        @deleteRowField="deleteAdminDistrict"
    />
    <template v-if="year.value && !loader">
      <Table
          v-for="(item, key) in regions"
          :key="key"
          :title="item.title"
          :descriptionList="[{title: $t('region'), width: 420}]"
          :actionText="$t('add-line')"
          :data="item.regions"
          :sort="true"
          hideEditIcon
          isDistrictRegion
          @addRowField="addRegions($event, item.id)"
          @changeRowField="editRegion($event, item.id)"
          @deleteRowField="deleteRegion($event, item.id)"
      />
    </template>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiNotification from "@/components/ui/UiNotification.vue";
import Table from "@/components/ui/UiTable.vue";

export default {
  name: "Regions",
  components: {Table, UiNotification, UiLoader, UiSelect},

  data () {
    return {
      year: {},
      type: {},
      subtype: {},
      loader: false,
      showNotification: false,
      message: '',
      regions: []
    }
  },

  methods: {
    ...mapActions([
      'years',
      'districts',
      'addDistrict',
      'editDistrict',
      'deleteDistrict',
      'districtRegions',
      'addDistrictRegions',
      'editDistrictRegion',
      'deleteDistrictRegion',
    ]),
    ...mapMutations(['setYears']),

    loadPage(message) {
      this.loader = true;
      this.districts().then(() => {
        this.regions = []
        this.getDistricts.forEach(el => {
          this.districtRegions({id: el.id, year: this.year.value}).then(() => {
            const regions = []
            this.getDistrictRegions.forEach(el => {
              regions.push({
                districtId: el.federalDistrictId,
                name: el.region.name,
                regionId: el.regionId,
                year: el.year,
                ord: el.ord,
                id: el.id
              })
            })
            const item = {
              title: el.name,
              regions: regions,
              id: el.id
            }
            this.regions.push(item);
          })
        })
        setTimeout(() => {
          this.showNotification = true
          this.loader = false;
          this.message = message
        }, 500)
      })
    },

    callApiDistrict () {
      this.regions = [];
      this.loader = true
      this.districts().then(() => {
        this.loader = false
        this.callApiDistrictRegions()
      })
    },

    addNewDistrict(data) {
      const payload = {
        name: data.name,
        id: data.id,
        ord: +data.ord,
        shortName: data.shortName
      }
      this.loader = true
      this.addDistrict(payload).then(() => {
        this.loadPage(this.$t('county-successfully-added'))
      })
    },

    editAdminDistrict(data) {
      const payload = {
        id: data.id,
        name: data.name,
        ord: +data.ord,
        shortName: data.shortName
      }
      this.loader = true
      this.editDistrict(payload).then(() => {
        this.loadPage(this.$t('county-successfully-update'))
      })
    },

    deleteAdminDistrict(data) {
      this.loader = true
      this.deleteDistrict(data).then(() => {
        this.loadPage(this.$t('county-successfully-deleted'))
      })
    },

    callApiDistrictRegions () {
      this.getDistricts.forEach(el => {
        this.districtRegions({id: el.id, year: this.year.value}).then(() => {
          const regions = []
          this.getDistrictRegions.forEach(el => {
            regions.push({
              districtId: el.federalDistrictId,
              name: el.region.name,
              regionId: el.regionId,
              year: el.year,
              ord: el.ord,
              id: el.id
            })
          })
          const item = {
            title: el.name,
            regions: regions,
            id: el.id
          }
          this.regions.push(item);
        })
      })
    },

    addRegions(data, id) {
      const payload = {
        id: id,
        regionId: data.regionVal.id,
        ord: +data.ord,
        year: this.year.value
      }
      this.loader = true;
      this.addDistrictRegions(payload).then(() => {
        this.loadPage(this.$t('region-successfully-added'))
      })
    },

    editRegion(data, id) {
      const payload = {
        districtId: id,
        id: data.id,
        regionId: data.regionVal.id,
        ord: +data.ord,
        year: this.year.value
      }
      this.loader = true;
      this.editDistrictRegion(payload).then(() => {
        this.loadPage(this.$t('region-successfully-updated'))
      })
    },

    deleteRegion(data, id) {
      const payload = {
        id: id,
        regionId: data.id
      }
      this.loader = true;
      this.deleteDistrictRegion(payload).then(() => {
        this.loadPage(this.$t('region-successfully-deleted'))
      })
    }
  },

  computed: {
    ...mapGetters(['getDistricts', 'getDistrictRegions', 'getYears'])
  },

  mounted() {
    this.setYears([])
    this.years().then((res) => {
      let data = res.map((elem) => {
        elem.name = elem.id
        elem.value = elem.id
        return elem
      })
      this.loader = false;
      this.setYears(data)
    })
  }
}
</script>

<style lang="scss" scoped>
.regions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1000px) {
    overflow-x: auto;
  }

  &__select {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 433px;
    width: 100%;

    ::v-deep .ui-select {
      z-index: auto;
      max-width: 305px;
    }
    ::v-deep .ui-select__label {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      p {
        font-size: 16px;
        line-height: 19px;
        color: #1B1A1F;
        white-space: nowrap;
      }
    }
  }
}
::v-deep .multiselect {
  z-index: 3;
}
</style>